<template>
  <v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12" md="10" lg="8">
	  		<v-card class="shadowCard" v-if="datosReciboPago">
	  			<v-card-title primary-title>
	  			  Folio de pago: {{ folio }}
	  			  <v-spacer></v-spacer>
	  			  <v-btn color="primary" small @click="initialize()">Actualizar</v-btn>
	  			</v-card-title>
	      	<v-list class="transparent" dense>
			      <v-list-item>
			        <v-list-item-title>
			        	Alumno:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.alumno }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Matricula:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.matricula }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Plantel:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.plantel }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Ciclo:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.ciclo }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Curso:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.curso }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Cantidad Recibida:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          $ {{ datosReciboPago.monto_pagado }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Cantidad en Letra:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ pago_letra }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Monto de Descuento por Pronto Pago:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.descuento }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Adeudo del Ciclo:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.adeudo }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Recibió:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			          {{ datosReciboPago.nombre_completo }}
			        </v-list-item-subtitle>
			      </v-list-item>

			      <v-list-item>
			        <v-list-item-title>
			        	Teléfono:
			        </v-list-item-title>

			        <v-list-item-subtitle class="text-left">
			         	<v-text-field
			         	  label="WhatsApp"
			         	  hide-details
			         	  type="number"
			         	  v-model="nuevoTelefono"
			         	  single-line
			         	  outlined
			         	  dense
			         	></v-text-field>
			        </v-list-item-subtitle>
			      </v-list-item>
			    </v-list>

			    <v-card-actions>
			    	<v-spacer></v-spacer>
			      <v-btn color="orange" dark @click="abrirLiga()">Imprimir Recibo</v-btn>
			      <v-btn color="primary" dark @click="enviarPorWhatsApp()">Enviar a WhatsApp</v-btn>
			    </v-card-actions>
			  </v-card>
	    </v-col>
	  </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
import axios from 'axios';
  import router from '@/router'
  import {mapActions , mapGetters} from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  const convertir = require('numero-a-letras');


  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data(){
      return{

      	// Alertas
	    	parametros:{
	        dialogError: false,
	        mensaje: '',
	        color: ''
	      },

	      respuestaAlerta:false,
	      loader: true,
	      cargar: false,

	      pago:null,

	      datosVendedora:null,
				datosAlumno:null,
				datosReciboPago:null,
				folio: 0,
				pago_letra:'',
				url:'',
				nuevoTelefono: ''
      }
    },

    computed:{
      ...mapGetters('login',['getdatosUsuario']),
    },

    async created(){
      await this.initialize()
    },

    
    methods:{
      ...mapActions('login',['validarUser','guardarInfo','GetInfoUser','salirLogin']),
      ...mapActions('login',['GetInfoUser']),

      initialize() {
      	this.cargar = true
      	this.folio = this.$route.params.idpago 

      	const payload = {
      		id_usuario: this.getdatosUsuario.iderp,
      		id_pago   : this.$route.params.idpago
      	}

        // Aquí se envían los datos del usuario para saber que perfil tiene
        this.$http.post('whatsapp.enviarrecibo', payload ).then((response) => {

        	this.datosVendedora  = response.data.datosVendedora
					this.datosAlumno     = response.data.datosAlumno
					this.datosReciboPago = response.data.datosReciboPago
					this.nuevoTelefono   = this.datosAlumno.telefono

					this.pago_letra      = convertir.NumerosALetras( this.datosReciboPago.monto_pagado ).toUpperCase()

					this.url = 'https://schoolofenglishadmin.com/reporteshelper/repoteDePagoParcial/' + this.folio + '/' + this.pago_letra

					this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirLiga(){
				window.open(this.url , '_blank');
      },

			enviarPorWhatsApp( ){
				if( !this.datosVendedora.whatsappservidor ){ return this.validarErrorDirecto('No cuentas con whatsapp configurado')}
				if( !this.datosVendedora.url_servidor ){ return this.validarErrorDirecto('No cuentas con whatsapp configurado')}

				if( !this.nuevoTelefono ){ return this.validarErrorDirecto('El alumno no cuenta con un WhatsApp configurado')}

				this.url_servidor = this.datosVendedora.url_servidor + 'whatsapp.recibo'

				const payload = {
					url       : this.url,
			    tos       : `521${this.nuevoTelefono}@c.us`,
			    from      : this.datosVendedora.whatsappservidor,
			    mensaje   : "Imagen enviada desde el servidor",
			    imagen    : true,
			    id_alumno : this.datosReciboPago.id_alumno,
			    id_grupo  : this.datosReciboPago.id_grupo,
			    id_usuario: this.getdatosUsuario.iderp,
			    escuela   : this.datosReciboPago.escuela,
			    actualizar: this.datosAlumno.telefono != this.nuevoTelefono ? true : false,
			    telefono  : this.nuevoTelefono
				}

				console.log( payload )

				this.cargar = true

				// Aquí se envían los datos del usuario para saber que perfil tiene
        this.$http.post(this.url_servidor, payload ).then((response) => {

        	this.validarSuccess('Puedes revisar en tu whatsApp que ya se haya enviado el comprobante saludos!')

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
			},

      school (){
        window.open("https://schoolofenglishadmin.com/");
      }
 
    }
  }
</script>